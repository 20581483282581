import React, { Component } from "react";
import moment from "moment/moment";
import { monthOptions, dayOptions } from "../constants/dateOptions";

class UserForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showCourseDel: false,
            showParent: false,
        };

        this.showCourseDel = this.showCourseDel.bind(this);
        this.showParent = this.showParent.bind(this);
        this.createYears = this.createYears.bind(this);
    }

    showCourseDel(e) {
        e.preventDefault();
        let age = moment().format("YYYY") - e.target.value;
        this.props.handleInputChange(e);
        if (age >= 26) {
            this.setState({
                showCourseDel: true
            });
        } else {
            this.setState({ showCourseDel: false });
        }
    }

    showParent(e) {
        e.preventDefault();

        this.setState(prevState => ({showParent: !prevState.showParent}));
    }

    createYears() {
        let years = [];
        for (let i = 2019; i > 1919; i--) {
            years.push(
                <option key={i} value={i.toString()}>
                    {i}
                </option>
            );
        }
        return years;
    }

    render() {
        return (
            <section className="calculator">
                <form
                    onSubmit={this.props.handleSubmit}
                    className={this.props.error === true ? "error" : ""}
                    tabIndex="0"
                    aria-label="You're almost there. Before we share your results, introduce yourself by
                      completing the form below."
                    aria-live="assertive"
                >
                    <h2>Almost there.</h2>
                    <p>
                        Before we share your results, introduce yourself! Please
                        complete the form below.
                    </p>
                    <div className="input-group">
                        <label htmlFor="firstName">First Name</label>
                        <input
                            type="text"
                            id="firstName"
                            name="firstName"
                            required
                            value={this.props.firstName}
                            onChange={this.props.handleInputChange}
                        />
                    </div>
                    <div className="input-group">
                        <label htmlFor="lastName">Last Name</label>
                        <input
                            type="text"
                            id="lastName"
                            name="lastName"
                            required
                            value={this.props.lastName}
                            onChange={this.props.handleInputChange}
                        />
                    </div>
                    <div className="input-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            required
                            value={this.props.email}
                            onChange={this.props.handleInputChange}
                        />
                    </div>
                    <div className="input-group input-date">
                        <label>Date of Birth</label>
                        <div className="select-group">
                            <select
                                id="birthMonth"
                                value={this.props.birthMonth}
                                name="birthMonth"
                                required
                                onChange={this.props.handleInputChange}
                                aria-label="birth month"
                            >
                                <option value="">Month</option>
                                {monthOptions.map((option, idx) => (
                                    <option key={idx} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="select-group">
                            <select
                                id="birthDay"
                                name="birthDay"
                                value={this.props.birthDay}
                                required
                                onChange={this.props.handleInputChange}
                                aria-label="birth day"
                            >
                                <option value="">Day</option>
                                {dayOptions.map((option, idx) => (
                                    <option key={idx} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="select-group">
                            <select
                                id="birthYear"
                                name="birthYear"
                                required
                                value={this.props.birthYear}
                                onChange={this.showCourseDel}
                                aria-label="birth year"
                            >
                                <option value="">Year</option>
                                {this.createYears()}
                            </select>
                        </div>
                    </div>
                    <div
                        className={
                            this.state.showCourseDel
                                ? "input-group"
                                : "input-group hidden"
                        }
                    >
                        <label>Desired Course Delivery</label>
                        <div className="select-group">
                            <select id="courseDel">
                                <option>Select Course Delivery</option>
                                <option value="0">On Campus</option>
                                <option value="online">Online</option>
                            </select>
                        </div>
                    </div>
                    <div className="parental-info">
                        { this.state.showParent === true ?
                        <div>
                            <div>
                                <button onClick={this.showParent}>Cancel</button>
                            </div>
                            <div className="input-group">
                                <label htmlFor="parentFirstName">First Name</label>
                                <input
                                    type="text"
                                    id="parentFirstName"
                                    name="parentFirstName"
                                    value={this.props.parentFirstName}
                                    onChange={this.props.handleInputChange}
                                />
                            </div>
                            <div className="input-group">
                                <label htmlFor="parentLastName">Last Name</label>
                                <input
                                    type="text"
                                    id="parentLastName"
                                    name="parentLastName"
                                    value={this.props.parentLastName}
                                    onChange={this.props.handleInputChange}
                                />
                            </div>
                            <div className="input-group">
                                <label htmlFor="parentEmail">Email</label>
                                <input
                                    type="email"
                                    id="parentEmail"
                                    name="parentEmail"
                                    value={this.props.parentEmail}
                                    onChange={this.props.handleInputChange}
                                />
                            </div>
                            <div className="input-group">
                                <label htmlFor="parentRelationship">Relationship</label>
                                <div className="select-group">
                                    <select
                                        id="parentRelationship"
                                        name="parentRelationship"
                                        value={this.props.parentRelationship}
                                        onChange={this.props.handleInputChange}
                                    >
                                        <option value=""></option>
                                        <option value="Mother">Mother</option>
                                        <option value="Father">Father</option>
                                        <option value="Legal Guardian">Legal Guardian</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        :
                        <div>
                            <button onClick={this.showParent}>Add a Parent or Legal Guardian's Information</button>
                        </div>
                        }
                    </div>
                    {this.props.error === true && (
                        <div className="alert warning">
                            <p aria-live="assertive" tabIndex="-1">
                                <strong>Oops!</strong> The form above must be
                                complete to see your results.
                            </p>
                        </div>
                    )}
                    <button id="userForm" type="submit">
                        Submit
                    </button>
                </form>
                <div className="container progress-indicator">
                    <ul>
                        <li>Calculate</li>
                        <li>
                            <strong>Introduce Yourself</strong>
                        </li>
                        <li>Results</li>
                    </ul>
                </div>
                <div className="container text-center">
                    <button onClick={this.props.startOver}>Back</button>
                </div>
            </section>
        );
    }
}

export default UserForm;
