import React, { Component } from "react";
import ReactPlayer from "react-player/youtube";

class Results extends Component {
  render() {
    return (
      <section className="calculator">
        <div id="result" className="results">
          <div id="scholarships" tabIndex="0" aria-live="assertive">
            {this.props.results.includes("barat") ? (
              <h3 id="barat">
                Based on what you shared, you may be eligible for the{" "}
                <strong>
                  Saints Scholarship for <span>$14,000</span>
                </strong>
                .
              </h3>
            ) : (
              ""
            )}
            {this.props.results.includes("duchesne") ? (
              <h3 id="duchesne">
                Based on what you shared, you may be eligible for the{" "}
                <strong>
                  Big Red Scholarship for <span>$11,000</span>
                </strong>
                .
              </h3>
            ) : (
              ""
            )}
            {this.props.results.includes("mouton") ? (
              <h3 id="mouton">
                Based on what you shared, you may be eligible for the{" "}
                <strong>
                  Maryville Opportunity Award for <span>$9,000</span>
                </strong>
                .
              </h3>
            ) : (
              ""
            )}
            {this.props.results.includes("cali") ? (
              <h3 id="cali">
                As a California resident, you’re eligible for the{" "}
                <strong>
                  <span>$5,000</span>
                  California Grant
                </strong>
                .
              </h3>
            ) : (
              ""
            )}
            {this.props.results.includes("cali-plus") ? (
              <h3 id="cali-plus">
                <strong>+ A $5,000 California Grant</strong>
              </h3>
            ) : (
              ""
            )}
            {this.props.results.includes("compete") ? (
              <h3 id="compete">
                You are also eligible to compete for our{" "}
                <strong>
                  <a
                    href="https://www.maryville.edu/admissions/financial-aid/scholarships"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Presidential and Trustee Scholarships
                  </a>
                </strong>
                .
              </h3>
            ) : (
              ""
            )}
            {this.props.results.includes("counselor") ? (
              <h3 id="counselor">
                Please call{" "}
                <strong>
                  <a href="tel:+18006279855">1-800-627-9855</a>
                </strong>{" "}
                to talk about your scholarship and grant eligibility with an
                Admissions Counselor.
              </h3>
            ) : (
              ""
            )}
          </div>
          <div>
            <h3 className="m-0">
              The Priority Scholarship Date is
              <strong> December 1st.</strong>
            </h3>
          </div>

          <ul className="cta mb-32">
            <li>
              <a
                onClick={(e) => { e.preventDefault(); return window.gtag_report_conversion('mss8CLTi04MYEJGtzs0C', 'https://www.maryville.edu/admissions/apply/'); }}
                href="https://www.maryville.edu/admissions/apply/"
                rel="_blank"
                className="btn btn-primary"
              >
                Apply Now (FREE)
              </a>
            </li>
            <li>
              <a
                onClick={(e) => { e.preventDefault(); return window.gtag_report_conversion('F6_zCN7c04MYEJGtzs0C', 'https://www.maryville.edu/about/visit/'); }}
                href="https://www.maryville.edu/about/visit/"
                rel="_blank"
                className="btn btn-secondary"
              >
                Visit Campus
              </a>
            </li>
            <li>
              <a
                onClick={(e) => { e.preventDefault(); return window.gtag_report_conversion('yB3JCLfi04MYEJGtzs0C', 'https://www.maryville.edu/academics/'); }}
                href="https://www.maryville.edu/academics/"
                rel="_blank"
                className="btn btn-secondary"
              >
                Academic Programs
              </a>
            </li>
          </ul>
          <p className="small" tabIndex="0">
            These scholarships are for first-time freshman looking to take
            courses on campus full time. Find information on{" "}
            <a
              href="https://www.maryville.edu/admissions/financial-aid/scholarships"
              target="_blank"
              rel="noopener noreferrer"
            >
              other types of scholarships
            </a>{" "}
            on our website.
          </p>
          <div className="player-wrapper">
            <ReactPlayer
              className="react-player"
              url="https://youtu.be/EewDgoMMyCg"
              width="100%"
              height="100%"
            />
          </div>
        </div>
        <div className="container progress-indicator">
          <ul>
            <li>Calculate</li>
            {/*<li>Introduce Yourself</li>*/}
            <li>
              <strong>Results</strong>
            </li>
          </ul>
        </div>
        <div className="container text-center">
          <button onClick={this.props.startOver}>Start Over</button>
        </div>
      </section>
    );
  }
}

export default Results;
