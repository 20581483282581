import React, { Component } from "react";

class Calculator extends Component {
  render() {
    return (
      <section className="calculator">
        <form
          onSubmit={this.props.handleSubmit}
          className={`animated slideInUp ${
            this.props.error === true ? "error" : ""
          }`}
          tabIndex="0"
          aria-label="You've earned it. Merit based. Need based. You name it. We've got it. That's because
                      we believe a highly personalized, active education should be as affordable as it is inspirational.
                      Go ahead and crunch some numbers with our scholarship calculator and see how much of the fifty
                      million dollars in scholarship money belongs to you. Enter what you have into the form below. We
                      need your GPA, ACT, or S-A-T score for the calculator."
        >
          <h2>You've Earned It!</h2>
          <p>
            Merit-based, need-based &ndash; you name it, we've got it. That's
            because we believe a highly personalized education should be as
            affordable as it is inspirational. Go ahead and crunch some numbers
            with our scholarship calculator and see how much of the $50+ million
            in scholarship money belongs to you.
          </p>
          <p>
            <strong>Enter what you have.</strong> Admission to Maryville is
            test-optional, but scholarships can be awarded based on your{" "}
            <strong>GPA, ACT, or SAT</strong> score.
          </p>
          <div className="input-group">
            <label htmlFor="gpa">What's your GPA?</label>
            <div className="select-group">
              <select id="gpa">
                <option value="">I'm not sure</option>
                <option value="4.0">3.75 or higher</option>
                <option value="3.5">3.5-3.74</option>
                <option value="3.0">3.0-3.49</option>
                <option value="2.5">2.5-2.99</option>
                <option value="2.0">2.49 or lower</option>
              </select>
            </div>
          </div>
          <div className="input-group">
            <label htmlFor="act">What's your ACT Score?</label>
            <div className="select-group">
              <select id="act">
                <option value="">I'm not sure</option>
                <option value="27">27 or higher</option>
                <option value="26">24-26</option>
                <option value="23">22-23</option>
                <option value="21">21 or Less </option>
              </select>
            </div>
          </div>
          <div className="input-group">
            <label htmlFor="sat">What's your combined SAT Score?</label>
            <div className="select-group">
              <select id="sat">
                <option value="">I'm not sure</option>
                <option value="1600">1220 or higher</option>
                <option value="1210">1110-1210</option>
                <option value="1100">1030-1100</option>
                <option value="1020">1020 or Less </option>
              </select>
            </div>
          </div>
          <div className="input-group inline">
            <label htmlFor="cali">Are you a California resident?</label>
            <input type="checkbox" id="cali" />
          </div>
          {this.props.error === true && (
            <div className="alert warning">
              <p aria-live="assertive" tabIndex="-1">
                <strong>Oops!</strong> We need at least one of your scores for
                the calculator.
              </p>
            </div>
          )}
          <button
            id="calculate"
            type="submit"
            className="btn btn-success btn-lg"
            aria-label="Calculate your scholarship"
          >
            Calculate
          </button>
        </form>
        <div className="container progress-indicator">
          <ul>
            <li>
              <strong>Calculate</strong>
            </li>
            {/*<li>Introduce Yourself</li>*/}
            <li>Results</li>
          </ul>
        </div>
      </section>
    );
  }
}

export default Calculator;
