import React, { Component } from 'react';

class BackgroundVideo extends Component {

    render () {
        return (
            <video id="background-video" loop autoPlay muted>
                <source src={process.env.PUBLIC_URL + '/video/39f4c1f06364ad761f19515490a9d80f.mp4'} type="video/mp4" />
            </video>
        )
    }
}

export default BackgroundVideo;