import React, { Component } from "react";
import moment from "moment/moment";
import axios from "axios";
import { ReactComponent as MaskSVG } from "../_assets/svg/mask.svg";
import BackgroundVideo from "../BackgroundVideo";
import Calculator from "../Calculator";
import "../_assets/scss/index.scss";
import UserForm from "../UserForm";
import Results from "../Results";
import { BrowserView, MobileView } from "react-device-detect";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "results",
      results: [],
      purlID: window.location.pathname.replace("/", ""),
      salesforceId: null,
      contactOrLead: "",
      responseLoaded: true,
      firstName: "",
      lastName: "",
      email: "",
      birthMonth: "",
      birthDay: "",
      birthYear: "",
      parentFirstName: "",
      parentLastName: "",
      parentEmail: "",
      parentRelationship: "",
      calcFormError: false,
      userFormError: false,
      submitError: false,
    };
    this.handleCalculatorSubmit = this.handleCalculatorSubmit.bind(this);
    this.handleUserFormSubmit = this.handleUserFormSubmit.bind(this);
    this.startOver = this.startOver.bind(this);
    this.getUserDetails = this.getUserDetails.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.scrollTop = this.scrollTop.bind(this);
  }

  getUserDetails(e) {
    if (this.state.purlID) {
      axios
        .post("https://salesforce.mediacross.com/maryville/get-object", {
          object: "Lead",
          fields: ["Id", "Email", "FirstName", "LastName", "Birthdate__c"],
          where: [
            {
              field: "Identifying_Lead_Number__c",
              value: this.state.purlID,
            },
          ],
        })
        .then((response) => {
          if (!response.data) {
            this.setState({contactOrLead: "Contact"});

            return axios
              .post("https://salesforce.mediacross.com/maryville/get-object", {
                object: "Contact",
                fields: ["Id", "Birthdate", "Email", "FirstName", "LastName"],
                where: [
                  {
                    field: "Identifying_Number__c",
                    value: this.state.purlID,
                  },
                ],
              });
          }

          this.setState({contactOrLead: "Lead"});

          return response;
        }).then((response) => {
          if (response.data) {
            let year = "";
            let month = "";
            let day = "";

            if (response.data.Birthdate) {
              year = moment(response.data.Birthdate, "YYYY-MM-DD").format(
                "YYYY"
              );
              day = moment(response.data.Birthdate, "YYYY-MM-DD").format(
                "DD"
              );
              month = moment(response.data.Birthdate, "YYYY-MM-DD").format(
                "MM"
              );
            }
            if (response.data.Birthdate__c) {
              year = moment(response.data.Birthdate__c, "YYYY-MM-DD").format(
                "YYYY"
              );
              day = moment(response.data.Birthdate__c, "YYYY-MM-DD").format(
                "DD"
              );
              month = moment(response.data.Birthdate__c, "YYYY-MM-DD").format(
                "MM"
              );
            }

            this.setState({
              firstName: response.data.FirstName,
              lastName: response.data.LastName,
              email: response.data.Email,
              birthYear: year,
              birthDay: day,
              birthMonth: month,
              salesforceId: response.data.Id,
              responseLoaded: true,
            });
          } else {
            this.setState({
              responseLoaded: true,
            });
          }
        })
        .catch(() => {
          this.setState({
            responseLoaded: true,
          });
        });
    } else {
      this.setState({
        responseLoaded: true,
      });
    }
  }

  handleInputChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleCalculatorSubmit(e) {
    e.preventDefault();

    window.gtag_report_conversion('MAdRCJGN1YMYEJGtzs0C');

    const gpa = e.target.gpa.value;
    const act = e.target.act.value;
    const sat = e.target.sat.value;
    const california = e.target.cali.checked;

    if (gpa || act || sat) {
      let results = [];
      results.push("counselor");

      if (gpa >= 2.5 || act >= 22 || sat >= 1030) {
        results = [];
        results.push("mouton");
      }

      if (gpa >= 3.0 || act >= 24 || sat >= 1110) {
        results = [];
        results.push("duchesne");
      }

      if (gpa >= 3.5 || act >= 27 || sat >= 1220) {
        results = [];
        results.push("barat");
      }

      if (gpa >= 3.75 || act >= 27 || sat >= 1220) {
        results = [];
        results.push("barat");
        results.push("compete");
      }

      if (california) {
        if (results.includes("counselor")) {
          results.push("cali");
        } else {
          results.push("cali-plus");
        }
      }

      this.setState({
        results: results,
        //status: "userform",
        status: "results",
      });

      this.scrollTop();
    } else {
      this.setState({
        results: [],
        status: "calculator",
        calcFormError: true,
      });
    }
  }

  async handleUserFormSubmit(e) {
    e.preventDefault();

    let firstName = e.target.firstName.value;
    let lastName = e.target.lastName.value;
    let email = e.target.email.value;
    let dob =
      e.target.birthMonth.value +
      "/" +
      e.target.birthDay.value +
      "/" +
      e.target.birthYear.value;

    if (firstName && lastName && email && dob) {
      let userAge = moment().diff(moment(dob, "MM/DD/YYYY"), "years");
      let desiredCourseDelivery = "0";
      if (e.target.courseDel.value) {
        desiredCourseDelivery = e.target.courseDel.value;
      }

      if ((!this.state.contactOrLead) || this.state.contactOrLead === "Contact") {
        let studentType = "";
        let campus = "";

        if (userAge < 20) {
          studentType = "First-time Freshman";
          campus = "Main Campus, Traditional";
        } else if (userAge >= 20 && userAge < 26) {
          studentType = "Transfer";
          campus = "Main Campus, Traditional";
        } else {
          studentType = "Adult Undergraduate";
          if (desiredCourseDelivery === "online") {
            campus = "Partner Online Program";
          } else {
            campus = "Main Campus, Saint Louis, MO";
          }
        }

        let userDetails = {
          object: "Contact",
          fields: [
            {
              name: "FirstName",
              value: firstName,
            },
            {
              name: "LastName",
              value: lastName,
            },
            {
              name: "Email",
              value: email,
            },
            {
              name: "Birthdate",
              value: moment(dob, "MM/DD/YYYY").format("YYYY-MM-DD"),
            },
            {
              name: "TargetX_SRMb__Status__c",
              value: "Inquiry",
            },
            {
              name: "TargetX_SRMb__Source__c",
              value: "Search Response",
            },
            {
              name: "TargetX_SRMb__Student_Type__c",
              value: studentType,
            },
            {
              name: "TargetX_SRMb__Campus__c",
              value: campus,
            }
          ],
          create_only: [
            {
              name: "TargetX_SRMb__Anticipated_Start_Term__c",
              value: "Fall",
            },
            {
              name: "TargetX_SRMb__Anticipated_Start_Year__c",
              value: "2021",
            },
          ],
          where: [
            {
              field: "Identifying_Number__c",
              value: this.state.purlID,
            },
          ],
        };

        axios({
          method: "POST",
          url: "https://salesforce.mediacross.com/maryville/update-object",
          data: userDetails,
        }).then(updateResponse => {
          if (this.state.parentEmail && this.state.parentFirstName && this.state.parentLastName && this.state.parentRelationship) {
            let contactId = null;

            if (updateResponse.data && updateResponse.data.id) {
              contactId = updateResponse.data.id;
            } else {
              contactId = this.state.salesforceId;
            }

            axios({
              method: "POST",
              url: "https://salesforce.mediacross.com/maryville/update-object",
              data: {
                object: "TargetX_SRMb__Family_Relationship__c",
                fields: [
                  {name: "TargetX_SRMb__Relationship__c", value: this.state.parentRelationship},
                  {name: "TargetX_SRMb__First_Name__c", value: this.state.parentFirstName},
                  {name: "TargetX_SRMb__Last_Name__c", value: this.state.parentLastName},
                  {name: "TargetX_SRMb__Email__c", value: this.state.parentEmail},
                  {name: "Type__c", value: "Parent/Legal Guardian"},
                ],
                create_only: [
                  {name: "TargetX_SRMb__Contact__c", value: contactId}
                ],
                where: [{field: "TargetX_SRMb__Contact__c", value: contactId}]
              },
            });
          }
        });
      }

      if (this.state.contactOrLead === "Lead") {
        let leadDetails = {
          object: "Lead",
          fields: [
            {
              name: "FirstName",
              value: firstName,
            },
            {
              name: "LastName",
              value: lastName,
            },
            {
              name: "Email",
              value: email,
            },
            {
              name: "Birthdate__c",
              value: moment(dob, "MM/DD/YYYY").format("YYYY-MM-DD"),
            },
            {
              name: "Ready_for_Conversion__c",
              value: true
            },
            {
              name: "Ready_for_Conversion_Date__c",
              value: moment().format('YYYY-MM-DD')
            },
            {
              name: "Parent_Email__c",
              value: this.state.parentEmail ? this.state.parentEmail : null
            },
            {
              name: "Parent_First_Name__c",
              value: this.state.parentFirstName ? this.state.parentFirstName : null
            },
            {
              name: "Parent_Last_Name__c",
              value: this.state.parentLastName ? this.state.parentLastName : null
            },
            {
              name: "Parent_Relationship__c",
              value: this.state.parentRelationship ? this.state.parentRelationship : null
            }
          ],
          where: [
            {
              field: "Identifying_Lead_Number__c",
              value: this.state.purlID,
            },
          ],
        };

        axios({
          method: "POST",
          url: "https://salesforce.mediacross.com/maryville/update-object",
          data: leadDetails,
        });
      }

      this.setState({
        status: "results",
      });

      this.scrollTop();
    } else {
      this.setState({
        status: "userform",
        userFormError: true,
      });
    }
  }

  startOver() {
    this.setState({
      status: "calculator",
      results: [],
    });
    this.scrollTop();
  }

  scrollTop() {
    window.scrollTo(0, 0);
  }

  componentDidMount() {
    this.scrollTop();
    //this.getUserDetails(this.state.purlID);
    this.setState({
      status: "calculator",
      results: [],
    });
  }

  render() {
    if (this.state.status === "calculator") {
      return (
        <div className="App">
          <header className="animated fadeIn slow">
            <MaskSVG className="svg-mask" />
            <MobileView>
              <img
                className="gif-mobile-fallback"
                src={`${process.env.PUBLIC_URL}/images/video.gif`}
                alt="Animation of cash raining from the sky"
              />
            </MobileView>
            <BrowserView>
              <BackgroundVideo />
            </BrowserView>
            {this.state.responseLoaded && (
              <div
                className="message animated slideInLeft"
                tabIndex="0"
                aria-label="Seize your scholarship. Find the funds to fuel your future."
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/logo@2x.png`}
                  alt="Maryville University logo"
                  className="logo"
                />
                <h1>
                  {this.state.firstName && (
                    <span>
                      {this.state.firstName},<br />
                    </span>
                  )}
                  Seize Yo<span className="crimson">u</span>r
                  <br />
                  Scholarship
                </h1>
                <p>Find the funds to fuel your future.</p>
              </div>
            )}
          </header>
          <Calculator
            handleSubmit={this.handleCalculatorSubmit}
            responseLoaded={this.state.responseLoaded}
            error={this.state.calcFormError}
          />
        </div>
      );
    } else if (this.state.status === "userform") {
      return (
        <div className="App">
          <header className="animated fadeIn slow small-header">
            <MobileView>
              <img
                className="gif-mobile-fallback"
                src={`${process.env.PUBLIC_URL}/images/video.gif`}
                alt="Animation of cash raining from the sky"
              />
            </MobileView>
            <BrowserView>
              <BackgroundVideo />
            </BrowserView>
          </header>
          <UserForm
            handleInputChange={this.handleInputChange}
            handleSubmit={this.handleUserFormSubmit}
            age={this.state.age}
            startOver={this.startOver}
            firstName={this.state.firstName}
            lastName={this.state.lastName}
            email={this.state.email}
            birthMonth={this.state.birthMonth}
            birthDay={this.state.birthDay}
            birthYear={this.state.birthYear}
            parentFirstName={this.state.parentFirstName}
            parentLastName={this.state.parentLastName}
            parentEmail={this.state.parentEmail}
            parentRelationship={this.state.parentRelationship}
            error={this.state.userFormError}
          />
        </div>
      );
    } else if (this.state.status === "results") {
      return (
        <div className="App">
          <header className="animated fadeIn slow small-header">
            <MobileView>
              <img
                className="gif-mobile-fallback"
                src={`${process.env.PUBLIC_URL}/images/video.gif`}
                alt="Animation of cash raining from the sky"
              />
            </MobileView>
            <BrowserView>
              <BackgroundVideo />
            </BrowserView>
          </header>
          <Results results={this.state.results} startOver={this.startOver} />
        </div>
      );
    }
  }
}

export default App;
